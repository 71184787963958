import React from "react"
import styled from "@emotion/styled";


const projects = [
    {
        name: "Jax Ray training",
        description: "Model parallele training using Jax and Ray"
    },
    {
        name: "C++ HLO",
        description: "Build a model using jax and flax in python and train it using C++",
    }
];

const MainContainer = styled.div`
    margin: 0 1em 0 1em;
`;

const Project = ({project}) => (
    <>
        <h2>{project.name}</h2>
        <p>{project.description}</p>
    </>
)

const Projects = () => {
    return <MainContainer>
        <h1>Here are some of the projects I've done:</h1>
        {
            projects.map(project => <Project project={project}/>)
        }
    </MainContainer>
}

export default Projects